import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import 'assets/fontYekanBakh.css';
import { AryAlertTypes } from 'framework/base/components/AryAlert';
import {
  GET_DESCRIPTION_TEMPLATES,
  GET_SYSTEM_SETTINGS
} from 'framework/base/constants/aryGraphqlSchemas';
import arySystemSettings from 'framework/base/constants/arySystemSettings';
import DescriptionTemplatesContext from 'framework/base/contexts/DescriptionTemplatesContext';
import LayoutTypeContext from 'framework/base/contexts/LayoutTypeContext';
import ShowCurrenciesContextProvider from 'framework/base/contexts/ShowCurrenciesContextProvider';
import SystemSettingsContext from 'framework/base/contexts/SystemSettingsContext';
import useAryAlert from 'framework/base/hooks/useAryAlert';
import { useAryLazyQuery } from 'framework/base/hooks/useAryGraphql';
import useAryLayoutType from 'framework/base/hooks/useAryLayoutType';
import useTemplateParsing from 'framework/base/hooks/useTemplateParsing';
import AryLoadingAnimation from 'framework/desktop/AryLoadingAnimation';
import 'language/translation';
import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  setDoBootstrap,
  setErrors,
  updateUserCurrencies
} from 'redux/slices/systemSlice';
import appRoutes from 'routes';
import {
  ApiAuthorizationConstants,
  AuthRouteGuard as AuthGuard,
  LoginCallbackHandler,
  authService
} from 'spec';
import { prefixer } from 'stylis';
import stylisRTLPlugin from 'stylis-plugin-rtl';

const { ApplicationPaths } = ApiAuthorizationConstants;

const AryNoVersionModal = lazy(() =>
  import('framework/base/components/AryNoVersionModal')
);

const DEFAULT_LANGUAGE = 'fa';

const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
  stylisPlugins: [prefixer, stylisRTLPlugin]
});

const DirectionProvider = ({ rtl = false, children }) => {
  if (!rtl) {
    return children;
  }
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

function lazyRoutes(routes, layoutType) {
  if (!routes || routes.length === 0) return null;
  return (
    <>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard
              anonymousAccess={route.anonymousAccess}
              authKeys={route.authKeys}
              path={route.path}
              component={
                layoutType === 'mobile' && route.mobile
                  ? route.mobile
                  : route.component
              }
            />
          }
        >
          {lazyRoutes(route.routes, layoutType)}
        </Route>
      ))}
    </>
  );
}

const contentTypes = {
  loading: 0,
  settings: 1,
  application: 2
};

const App = () => {
  const { t, i18n } = useTranslation();
  const reduxStore = useStore();
  const layoutType = useAryLayoutType();
  const langDirection = t('dir');
  const [AryAlert, showAlert] = useAryAlert({
    onClose: () => {
      reduxStore.dispatch(setErrors([]));
    }
  });
  const intervalId = useRef(null);
  document.body.dir = langDirection;

  const [contentStatus, setContentStatus] = useState(contentTypes.loading);
  const [systemSettings, setSystemSettings] = useState({});
  const [noVersionModalOpen, setNoVersionModalOpen] = useState(false);

  const [
    getSystemSettings,
    { data: getSystemSettingsQueryData, loading: getSystemSettingsLoading }
  ] = useAryLazyQuery({
    gqlConfig: GET_SYSTEM_SETTINGS,
    items: `
      systemLanguage
      mainCurrency
      isGregorian
      defaultPhonePrefix
      profitAndLossAccuracy
      documents {
        documentTitle
        isManual
        issuingOrganization
      }
      userCurrencies {
        currencyCode
        decimalPlaces
        dollarRate
        isDivision
      }
      fileId
      exchangeName
      address
      slogan
      descriptions
      groupNames
      phoneNumbers {
        countryCode
        phoneNumber
        communicationWays
      }
      fontSize
      isDarkMode
      timeZone
      showCurrencyByTitle
      isDigitsLatin
      showReportOnWeb
    `
  });

  const [getDescriptionTemplates, { data: descriptionTemplates }] =
    useAryLazyQuery({
      gqlConfig: GET_DESCRIPTION_TEMPLATES,
      items: `
          id
          body
        `
    });

  async function bootstrap() {
    const resp = await getSystemSettings();
    await getDescriptionTemplates(resp?.data?.language || DEFAULT_LANGUAGE);
    if (!intervalId.current) {
      authService.checkSession();
      intervalId.current = setInterval(() => {
        authService.checkSession();
      }, 30000);
    }
  }

  const parseDescription = useTemplateParsing(descriptionTemplates);

  function hasSystemSettings(settings) {
    if (!settings) return false;
    if (
      !settings.mainCurrency ||
      !settings.profitAndLossAccuracy ||
      !settings.userCurrencies?.length ||
      !settings.exchangeName ||
      !settings.groupNames?.length
    ) {
      return false;
    }
    return true;
  }

  function getBaseRoute() {
    switch (contentStatus) {
      case contentTypes.loading:
        return <Route path="/*" element={<AryLoadingAnimation />} />;
      case contentTypes.settings: {
        window.location.href = window.paths.acc;
        return <Route path="/*" element={<Navigate replace to="/" />} />;
      }
      case contentTypes.application: {
        if (!descriptionTemplates?.length) {
          return <Route path="/*" element={<AryLoadingAnimation />} />;
        }
        return <>{lazyRoutes(appRoutes, layoutType)}</>;
      }
      default:
        return null;
    }
  }

  reduxStore.subscribe(() => {
    const { errors } = reduxStore.getState().systemReducer;
    if (errors.length) {
      showAlert(
        AryAlertTypes.ERROR,
        parseDescription(errors[0].templateId, errors[0].metadata)
      );
    }
  });

  useEffect(() => {
    if (window.location.pathname !== ApplicationPaths.LoginCallback) {
      bootstrap();
    }

    reduxStore.subscribe(() => {
      const { noVersionError, doBootstrap } =
        reduxStore.getState().systemReducer;
      if (noVersionError) setNoVersionModalOpen(true);
      if (doBootstrap) {
        reduxStore.dispatch(setDoBootstrap(false));
        bootstrap();
      }
    });
  }, []);

  useEffect(() => {
    if (!getSystemSettingsLoading && getSystemSettingsQueryData) {
      if (hasSystemSettings(getSystemSettingsQueryData)) {
        i18n.changeLanguage(
          getSystemSettingsQueryData[arySystemSettings.systemLanguage] || 'fa'
        );
        setSystemSettings(getSystemSettingsQueryData);
        setContentStatus(contentTypes.application);
        window.paths.rptf =
          layoutType === 'mobile'
            ? window.paths.rptw
            : getSystemSettingsQueryData[arySystemSettings.showReportOnWeb]
            ? window.paths.rptw
            : window.paths.rptd;
        reduxStore.dispatch(updateUserCurrencies(true));
      } else {
        setContentStatus(contentTypes.settings);
      }
    }
  }, [getSystemSettingsQueryData]);

  useEffect(
    () => () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    },
    []
  );

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s | ${t('Moneyx')}`}
        defaultTitle={t('Moneyx Reports')}
      />
      <SystemSettingsContext.Provider value={systemSettings}>
        <DirectionProvider rtl={langDirection === 'rtl'}>
          <LayoutTypeContext.Provider value={layoutType}>
            <DescriptionTemplatesContext.Provider value={descriptionTemplates}>
              <ShowCurrenciesContextProvider>
                <Routes>
                  {getBaseRoute()}
                  <Route
                    exact
                    path={ApplicationPaths.LoginCallback}
                    element={<LoginCallbackHandler />}
                  />
                </Routes>
                {AryAlert}
                {noVersionModalOpen && (
                  <Suspense fallback="">
                    <AryNoVersionModal
                      open={noVersionModalOpen}
                      handleClose={() => setNoVersionModalOpen(false)}
                    />
                  </Suspense>
                )}
              </ShowCurrenciesContextProvider>
            </DescriptionTemplatesContext.Provider>
          </LayoutTypeContext.Provider>
        </DirectionProvider>
      </SystemSettingsContext.Provider>
    </HelmetProvider>
  );
};

export default App;
