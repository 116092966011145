import { useTranslation } from 'react-i18next';
import { parseDescriptionTemplate } from '../utils/ReportingUtils';
import useDescriptionTemplatesContext from './useDescriptionTemplatesContext';

const useTemplateParsing = (templates) => {
  const { t } = useTranslation();
  const descriptionTemplates = useDescriptionTemplatesContext();

  const parseDescriptionFunction = (tId, metadata) =>
    parseDescriptionTemplate(
      templates || descriptionTemplates,
      t,
      tId,
      metadata
    );

  return parseDescriptionFunction;
};

export default useTemplateParsing;
