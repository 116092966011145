import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { imageAddressPrefix } from 'spec';
import './errors.css';

const Error401 = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet title="خطای 401 - صفحه در دسترس شما نیست" />
      <div className="errors-wrapper">
        <div className="error-img--wrapper">
          <img
            src={`${imageAddressPrefix}static/img/errors/error401.svg`}
            alt="Error401"
          />
        </div>
        <div className="error-alert--wrapper">
          <h2 className="error-alert-text">
            صفحه در دسترس شما نیست!
          </h2>
        </div>
        <div className="error-text--wrapper">
          <h5 className="error-text">
            شما دسترسی به این صفحه را ندارید.
          </h5>
        </div>
        <div className="error-btn--wrapper">
          <button className="error-btn" type="button" onClick={() => navigate(-1)}>
            بازگشت
          </button>
        </div>
      </div>
    </>
  );
};

export default Error401;
