import AryLoadingAnimation from 'framework/desktop/AryLoadingAnimation';
import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import authService from './AuthorizeService';

const LoginCallbackHandler = () => {
  const [code, setCode] = useState(null);
  const navigate = useNavigate();
  const reduxStore = useStore();

  useEffect(() => {
    if (code) {
      authService.fetchRefreshToken(code, navigate, reduxStore.dispatch);
    }
  }, [code]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setCode(params.get('code'));
  }, []);

  return <AryLoadingAnimation />;
};

export default LoginCallbackHandler;
