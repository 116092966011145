import React from 'react';
import './aryLoadingAnimation.css';

const AryLoadingAnimation = () => (
  <div>
    <div className="loading-container">
      <svg viewBox="0 0 138 133" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect className="rect" width="138" height="133" fill="white" />
        <path
          className="firstLeftWing"
          d="M42.0655 43.9487C43.4266 41.4015 46.0087 39.74 48.8909 39.5568L49.4154 39.5234L54.1065 50.6722L38.727 86.0402C36.7343 90.6228 32.2132 93.5869 27.2161 93.5869H19.7031L42.0655 43.9487Z"
          fill="#A1ACBF"
        />
        <path
          className="firstRightWing"
          d="M50.9141 39.4082H59.7296C63.3605 39.4082 66.6521 41.5426 68.1335 44.8576L89.9049 93.5767H82.7829C77.4113 93.5767 72.5597 90.3664 70.4596 85.4223L50.9141 39.4082Z"
          fill="#D1DBE8"
        />
        <path
          className="secondLeftWing"
          d="M60.5996 65.5576L48.074 93.5866H55.1643C60.5552 93.5866 65.4242 90.3648 67.5318 85.403L68.2695 83.6663L60.5996 65.5576Z"
          fill="#D1DBE8"
        />
        <path
          className="thirdLeftWing"
          d="M87.0391 39.4082H78.2156C74.5895 39.4082 71.3022 41.5398 69.8228 44.8503L69.6746 45.182L77.4295 62.0309L87.0391 39.4082Z"
          fill="#D1DBE8"
        />
        <path
          className="secondRightWing"
          d="M95.7722 43.9487C94.4068 41.4011 91.8216 39.7403 88.937 39.5575L88.3998 39.5234L83.6944 50.6722L99.1165 86.03C101.118 90.6197 105.65 93.5869 110.657 93.5869H118.203L95.7722 43.9487Z"
          fill="#A1ACBF"
        />
      </svg>
    </div>
  </div>
);

export default AryLoadingAnimation;
