import React from 'react';
import { MdClose } from 'react-icons/md';
import Modal from './Modal';

const AryModal = ({
  open,
  handleClose,
  title,
  noCloseOnOutsideClicked,
  noCloseIcon,
  width,
  height,
  justifyContent,
  alignItems,
  rowSpacing,
  columnSpacing,
  descriptionSpacingToTitle,
  style,
  children
}) => {
  const modalStyle = {
    width: width || '46.875rem',
    height: height || 'auto',
    ...style
  };
  const modalDatatyles = {
    'margin-top': descriptionSpacingToTitle || '1rem',
    'row-gap': rowSpacing || '1.5rem',
    'column-gap': columnSpacing || '1rem',
    justifyContent,
    alignItems
  };

  return (
    <Modal
      open={open}
      onClose={noCloseOnOutsideClicked ? () => { } : handleClose}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          handleClose();
        }
      }}
    >
      <div className="modal-box" style={{ ...modalStyle }}>
        <div className="modal-items--wrapper">
          <div className="modal-title--holder">
            <h3 className="modal-title">{title}</h3>
          </div>
          {!noCloseIcon && (
            <div className="icon-wrapper">
              <MdClose fontSize="1.5rem" onClick={handleClose} color="rgba(0, 0, 0, 0.54)" />
            </div>
          )}
        </div>
        <div
          className="modal-data"
          style={{ ...modalDatatyles }}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default AryModal;
