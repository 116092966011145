import React from 'react';

export default function Modal({ open, children }) {
    if (open) {
        return (
            <div className="modal">
                {children}
            </div>
        );
    }
}
