import React, { useState } from 'react';
import AryAlert from '../components/AryAlert';

const useAryAlert = ({ onClose, ...props } = {}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [alertType, setAlertType] = useState('');
  const [alertContent, setAlertContent] = useState(null);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const showAlert = (type, message, content) => {
    if (!type || !message) return;
    setTitle(message);
    setAlertType(type);
    if (content) {
      setAlertContent(content);
    }
    setOpen(true);
  };

  return [
    <AryAlert
      open={open}
      handleClose={handleClose}
      title={title}
      type={alertType}
      {...props}
    >
      {alertContent}
    </AryAlert>,
    showAlert
  ];
};

export default useAryAlert;
