const arySystemSettings = {
  systemLanguage: 'systemLanguage',
  mainCurrency: 'mainCurrency',
  fontSize: 'fontSize',
  isGregorian: 'isGregorian',
  profitAndLossAccuracy: 'profitAndLossAccuracy',
  documents: 'documents',
  isDarkMode: 'isDarkMode',
  file: 'file',
  exchangeName: 'exchangeName',
  phoneNumbers: 'phoneNumbers',
  address: 'address',
  slogan: 'slogan',
  descriptions: 'descriptions',
  groupNames: 'groupNames',
  userCurrencies: 'userCurrencies',
  bankInfos: 'bankInfos',
  showCurrencyByTitle: 'showCurrencyByTitle',
  timeZone: 'timeZone',
  isDigitsLatin: 'isDigitsLatin',
  showReportOnWeb: 'showReportOnWeb',
  defaultSendWhatsApp: 'defaultSendWhatsApp',
  defaultPhonePrefix: 'defaultPhonePrefix',
  defaultUpdateCreditLimit: 'defaultUpdateCreditLimit'
};

export default arySystemSettings;
